import React, { useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Container, Grid, IconButton, Typography, Button } from "@mui/material";
// Material Icons
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens

// Components
import Issue from "../issue";
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import groupBy from "lodash.groupby";

interface Props {
  onClose: () => void;
  edition: any;
  onIssueClicked: (issueId: any) => void;
  onNewIssueClicked: () => void;
  onIssueDeleted: () => void;
  tenantId: any;
}

const IssueList: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isXs ,isSm, isMd } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [issueList, setIssueList] = React.useState<any>();

  useEffect(() => {
    if (issueList) return;
    proxyExecute(`Issues/List?editionId=${props.edition.id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setIssueList(data) }
    })

  }, issueList);

  const closeIcon = () => {
    if (props.tenantId)
      return (
        <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
          <ArrowBackIcon />
        </IconButton>
      );
    return (
      <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
    );
  };

  const getTitle = () => {
    return <Typography className={classes.dialogTitle}>{props.edition.name}</Typography>;
  };

  const getFullYear = (issue: any) => {
    if (issue.year) {
      return issue.year;
    } else {
      const date = new Date(issue.date);
      return date.getFullYear();
    }
  };

  const renderIssues = () => {
    const issuesGroupByYear = groupBy(issueList, (issue: any) => getFullYear(issue));

    return Object.keys(issuesGroupByYear)
      .reverse()
      .map((key: string, index: number) => {
        return (
          <Grid key={index} item xs={12}>
            <Grid container>
              <Grid item >
                 <Grid container style={{ marginBottom: '12px' }}>
                  <Typography className={classes.groupTitle}>{key}</Typography>
                  {index === 0 && !isMd &&<Button variant="outlined" size="small" onClick={() => {
                    props.onNewIssueClicked && props.onNewIssueClicked();
                  }
                  }>
                    {t('Add New Issue')}
                  </Button>}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {issuesGroupByYear[key].map((issue: any) => {
                  return (
                    <Grid key={issue.id} item xs={isXs ? 6 : (isSm ? 4 : 2)}>
                      <Issue tenantId={props.tenantId} issueId={issue.id} onClick={() => { props.onIssueClicked && props.onIssueClicked(issue.id) }}
                        onIssueDeleted={() => { props.onIssueDeleted && props.onIssueDeleted(); }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      });
  };

  return (
    <Dialog
      open
      fullScreen
      closable={false}
      title={getTitle()}
      titleTextAlign="left"
      onClose={props.onClose}
      leftHeader={closeIcon()}
      showAlwaysTopDivider={true}
    >
      <Container
        disableGutters
        maxWidth="md"
        classes={{
          maxWidthMd: classes.maxWidthMd,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={9}>
              {renderIssues()}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default IssueList;