const EN = {
  general: {
    'Ok': 'OK',
    'Cancel': 'Cancel',
    'Welcome': 'Welcome',
    'Main Page': 'Home',
    'All': 'All',
    'Name': 'Name',
    'Surname': 'Surname',
    'Email': 'E-mail',
    'Authorized Posts': 'Shared with me',
    'Post': 'Post',
    '(Edition)': '(Edition)',
    '{{count}} kayıt listelendi': '{{count}} records listed',
    'Add Member': 'Add member',
    'Operation completed': 'Operation completed.',
    'Operation failed': 'Operation failed.',
    'Session expired.': 'Session expired.',
    'Request failed': 'Request failed.',
    'This field is required': 'This field is required.',
    'Next': 'Next',
    'Member Detail': 'Member detail',
    'Post Name': 'Post name',
    'Role': 'Role',
    'Related Posts': 'Related posts',
    'Add Related Post': 'Add related post',
    'Rows per page': 'Rows per page',
    'All issues of this post has been related': 'All issues of this post has been related',
    'Please select at least one content': 'Please select at least one content',
    'Add Post Relation': 'Add post relation',
    'Member': 'Member',
    'Writer': 'Writer',
    'Editor': 'Editor',
    'Post Manager': 'Post manager',
    'Manager': 'Manager',
    'Owner': 'Owner',
    'Can read all post': 'Can read all post',
    'Can read all post as a member': 'Can read all post as a member',
    'Can edit all contents of post, can add/delete content': 'Can edit all contents of post, can add / delete content',
    'Can edit all contents of post, can add/delete content, can edit post details, can add new issue': 'Can edit all contents of post, can add/delete content, can edit post details, can add new issue',
    'All issues': 'All issues',
    'Issue': 'Issue',
    'Add manager/admin': 'Add manager / admin',
    'Can do all operations except edit account settings': 'Can do all operations except edit account settings',
    'Members': 'Members',
    'Member Groups': 'Member groups',
    'Managers And Owners': 'Managers and owners',
    'Log In': 'Login',
    'Password': 'Password',
    'Remember Me': 'Remember me',
    'Forgot Password': 'Forgot password',
    'Select a profile': 'Select a profile',
    'Login with different user': 'Login with different user',
    'Draft': 'Draft',
    'Published': 'Published',
    'Authorized Post': 'Shared with me',
    'Add New Post': 'Add',
    'There is no authorized post': 'There is no authorized post.',
    'Category': 'Category',
    'Font': 'Font',
    'Writer (Optional)': 'Writer (Optional)',
    'Post Period': 'Post period',
    'Add Post': 'Publication information',
    'Publication': 'Publication',
    'PublicationDetail': 'Publication detail information',
    'Edition': 'Edition',
    'Book, catalog, guide, report (Published once)': 'Book, catalog, guide, report (Published once)',
    'Bulletin, magazine, report, daily (New issues published at regular intervals)': 'Bulletin, magazine, report, daily (New issues published at regular intervals)',
    'Issn': 'ISSN',
    'Isbn': 'ISBN',
    'Post Cover': 'Post cover',
    'Upload Cover': 'Upload cover',
    'Post cover image width must be minimum 600px': 'Post cover image width must be minimum 600px',
    'The file size must be no more than 1 MB': 'The file size must be no more than 1 MB',
    'Language': 'Language',
    'Turkish': 'Turkish',
    'English': 'English',
    'Year': 'Year',
    'Image could not load': 'Image could not load',
    'Imprint not added.':'Imprint not added.',
    'Daily': 'Daily',
    'Weekly': 'Weekly',
    'Monthly': 'Monthly',
    'Two Monthly': 'Two-monthly',
    'Quarterly': 'Quarterly',
    'Seasonal': 'Seasonal',
    'Semi Annual': 'Semi-annual',
    'Annual': 'Annual',
    'Not found': 'Not found',
    'Date': 'Date',
    'Issue Number': 'Issue number',
    'Add Issue': 'Add issue',
    'Issue detail': 'Issue information',
    'There is no post': 'There is no post',
    'Edit post detail': 'Edit post detail',
    'Delete post': 'Delete post',
    'Add New Issue': 'Add new issue',
    'Edit Issue Detail': 'Edit issue detail',
    'Delete Issue': 'Delete issue',
    'Publications': 'Publications',
    'Imprint': 'Imprint',
    'Publish': 'Publish',
    'Edit': 'Edit',
    'Delete': 'Delete',
    'Unpublish': 'Unpublish',
    'Contents': 'CONTENTS',
    'Add Chapter': 'Add chapter',
    'Add Content': 'Add content',
    'Add Chapter Title': 'Add chapter title',
    'Chapter Title': 'Chapter title',
    'Content Type': 'Content type',
    'Content Title': 'Content title',
    'Show Writer Name': 'Show writer name',
    'Add Content to Chapter': 'Add content to chapter',
    'Add content before chapters': 'Add content before chapters',
    'Add content after chapters': 'Add content after chapters',
    'Edit content detail': 'Edit content detail',
    'Delete content': 'Delete content',
    'Edit Imprint': 'Edit imprint',
    'Title': 'Title',
    'Value': 'Value',
    'Add Row': 'Add row',
    'Save': 'Save',
    'Upload image is in progress': 'Upload image is in progress',
    'Add Text Box': 'Add text box',
    'Text under image (Optional)': 'Text under image (Optional)',
    'Text': 'Text',
    'Link display name (Optional)': 'Link display name (optional)',
    'Link (Optional)': 'Link (optional)',
    'Record will be deleted': 'Record will be deleted',
    'Delete all content': 'Delete all content',
    'Contact Us': 'Contact us',
    'Settings': 'Settings',
    'Team': 'Members',
    'Create Account':'Create account',
    'Log out':'Log out',
    'Account name':'Account name'
  },
  login: {
    signIn: "Oturum aç",
    password: "Password",
    rememberMe: "Remember me",
    next: "Next",
    email: "E-mail",
    forgotPassword: "Forgot password",
  },
  forgotPassword: {
    next: "Next",
    email: "E-mail",
    forgotPassword: "Forgot password",
    sendForgotPasswordEmailMessage:
      "E-posta adresinize şifre sıfırlama bilgilerini içeren bir e-posta gönderildi (spam klasörünüzü de kontrol etmeniz önerilir).",
    sendForgotPasswordInfo:
      "E-posta adresinizi girip İleri butonuna bastıktan sonra şifrenizi nasıl yenileceğinizle ilgili bilgiler e-posta adresinize gönderilecektir.",
  },
  error: {
    resetPassword: "Daha önce şifre sıfırla yapılmış.",
    alreadyActivated: "Hesabınız zaten aktifleştirilmiş.",
    email: "Hatalı e-posta adresi.",
    required: "Bu alan zorunludur.",
    passwordMustMatch: "Şifre ve şifre tekrarı bir biri ile eşleşmiyor.",
    unauthorized: "Oturum süresi dolmuştur. Lütfen tekrar giriş yapınız.",
    protectedPageMessage: "Bu sayfayı görebilmek için giriş yapmayı deneyiniz.",
    passwordMatches: "Parola, en az 8 karakter olmalı, sayı ve !@#$% gibi özel karakterler içermelidir.",
  },
  success: {
    accountActivated: "Hesabınız aktifleştirildi. Giriş yapabilirsiniz.",
  },
  home: {
    emptyMessage: "Henüz yayınınız bulunmamaktadır.",
    addNewPost: "Yeni yayın ekle",
    addNewIssue: "Yeni sayı ekle",
    addPost: "Yayın ekle",
    postName: "Yayın adı",
    issn: "ISSN",
    isbn: "ISBN",
    draft: "Taslak",
    published: "Yayınlananlar",
    iAmAuthorized: "Shared with me",
  },
  createAccount: {
    createAccount: "Hesap oluştur",
    name: "Adı",
    surname: "Soyadı",
    email: "E-posta",
    password: "Parola",
    rePassword: "Parola tekrar",
    next: "İleri",
    login: "Oturum aç",
    hasAccount: "Hesabın varsa",
    passwordInfo: "Parola, en az 8 karakter olmalı, sayı ve sembol içermelidir.",
    sozlesme: "<2>Kullanıcı Sözleşmesi</2> ve <2>Gizlilik İlkeleri</2>’ni kabul ediyorum.",
    'Account name':'Hesap adı'
  },
  authorized: {
    emptyMessage: "Paylaşılan yayın bulunmamaktadır.",
    draft: "Taslak",
    published: "Yayınlananlar"
  }
};

export default EN;
