import clsx from "clsx";
import AppBar from "components/appBar";
import { useLocalStorage } from "hooks";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { loggedOut, selectUnauthorized } from "store/authentication/reducer";

import { Container, Grid } from "@mui/material";

import useStyles from "./useStyles";

interface Props {
  showAppBar?: boolean;
  onlyShowLogo?: boolean;
  children: React.ReactNode;
}

const PageContainer: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isUnauthorized = useAppSelector(selectUnauthorized);
  const [, setLocalStorageToken] = useLocalStorage("token", "");

  useEffect(() => {
    if (isUnauthorized) {
      dispatch(loggedOut());
      setLocalStorageToken("");
      history.push("giris-yap");
    }
  }, [isUnauthorized]);

  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.container, {
        [classes.paddingTop]: props.showAppBar,
      })}
    >
      {props.showAppBar && (
        <Grid item style={{backgroundColor:'red'}}>
          <AppBar onlyShowLogo={props.onlyShowLogo} />
        </Grid>
      )}
      {/* <Container disableGutters={true} className={classes.bottomContainer}> */}
        <Grid container direction="column">
          {props.children}
        </Grid>
      {/* </Container> */}
    </Grid>
  );
};

PageContainer.defaultProps = {
  showAppBar: true,
};

export default PageContainer;
