const TR = {
  general: {
    'Ok': 'Tamam',
    'Cancel': 'Vazgeç',
    'Welcome': 'Hoş geldiniz',
    'Main Page': 'Ana sayfa',
    'All': 'Tümü',
    'Name': 'Adı',
    'Surname': 'Soyadı',
    'Email': 'E-posta',
    'Authorized Posts': 'Yetkili olduğu yayınlar',
    'Post': 'Yayın',
    '(Edition)': '(Süreli yayın)',
    '{{count}} records listed': '{{count}} kayıt listelendi',
    'Add Member': 'Üye ekle',
    'Operation completed': 'İşlem tamamlandı.',
    'Operation failed': 'İşlem hata aldı.',
    'Session expired': 'Oturum sonlandı.',
    'Request failed': 'İstek hata aldı.',
    'This field is required': 'Bu alan zorunludur.',
    'Next': 'İleri',
    'Member Detail': 'Üye detayı',
    'Post Name': 'Yayın adı',
    'Role': 'Rol',
    'Related Posts': 'İlişkili olduğu yayınlar',
    'Add Related Post': 'Yayın ilişkisi ekle',
    'Rows per page': 'Sayfa başına satır sayısı',
    'All issues of this post has been related': 'Bu yayına ait tüm sayılar ilişkilendirildi.',
    'Please select at least one content': 'En az bir içerik seçiniz.',
    'Member': 'Okur',
    'Writer': 'Yazar',
    'Editor': 'Editör',
    'Post Manager': 'Yayın yönetmeni',
    'Manager': 'Yönetici',
    'Owner': 'Sahip',
    'Can read all post': 'Yayının tümünü okuyabilir',
    'Can read all post as a member': 'Okur olarak yayının tümünü okuyabilsin',
    'Can edit all contents of post, can add/delete content': 'Yayının tüm içeriklerini düzenleyebilir, içerik ekleyip silebilir',
    'Can edit all contents of post, can add/delete content, can edit post details, can add new issue': 'Yayının tüm içeriklerini düzenleyebilir, içerik ekleyip silebilir, yayın bilgilerini düzenleyebilir, yeni sayı ekleyebilir',
    'All issues': 'Tüm sayılar',
    'Issue': 'Sayı',
    'Add manager/admin': 'Yönetici / sahip ekle',
    'Can do all operations except edit account settings': 'Hesap bilgilerini düzenlemek haricinde tüm işlemleri yapabilir',
    'Members': 'Üyeler',
    'Member Groups': 'Üye grupları',
    'Managers And Owners': 'Yöneticiler ve sahipler',
    'Log In': 'Oturum aç',
    'Password': 'Şifre',
    'Remember Me': 'Beni hatırla',
    'Forgot Password': 'Şifremi unuttum',
    'Select a profile': 'Bir profil seçin',
    'Login with different user': 'Farklı bir hesapla giriş yapın',
    'Draft': 'Taslaklar',
    'Published': 'Yayınlananlar',
    'Authorized Post': 'Benimle paylaşılanlar',
    'Add New Post': 'Ekle',
    'There is no authorized post': 'Yetkilendirilmiş yayın bulunmamaktadır.',
    'Category': 'Kategori',
    'Font': 'Yazı tipi',
    'Writer (Optional)': 'Yazar (İsteğe bağlı)',
    'Post Period': 'Yayın periyodu',
    'Add Post': 'Yayın bilgileri',
    'Publication': 'Süresiz yayın',
    'PublicationDetail': 'Yayın bilgileri',
    'Edition': 'Süreli yayın',
    'Book, catalog, guide, report (Published once)': 'Kitap, katalog, kılavuz, rapor… (Bir kez yayınlanır)',
    'Bulletin, magazine, report, daily (New issues published at regular intervals)': 'Bülten, dergi, rapor, günlük… (Belirli aralıklarla yeni sayı yayınlanır)',
    'Issn': 'ISSN',
    'Isbn': 'ISBN',
    'Post Cover': 'Yayın kapağı',
    'Upload Cover': 'Kapak yükle',
    'Post cover image width must be minimum 600px': 'Yayın kapak görseli genişliği minimum 600px olmalıdır',
    'The file size must be no more than 1 MB': 'Dosya boyutu en fazla 1 MB olmalıdır',
    'Language': 'Dil',
    'Turkish': 'Türkçe',
    'English': 'İngilizce',
    'Year': 'Yıl',
    'Image could not load': 'Resim yüklenemedi.',
    'Daily': 'Günlük',
    'Weekly': 'Haftalık',
    'Monthly': 'Aylık',
    'Two Monthly': '2 aylık',
    'Quarterly': '3 aylık',
    'Seasonal': 'Mevsimlik',
    'Semi Annual': '6 aylık',
    'Annual': 'Yıllık',
    'Not found': 'Bulunamadı',
    'Date': 'Tarih',
    'Issue Number': 'Sayı no',
    'Add Issue': 'Sayı ekle',
    'Issue detail': 'Sayı bilgileri',
    'There is no post': 'Henüz yayınınız bulunmamaktadır.',
    'Edit post detail': 'Yayın bilgileri düzenle',
    'Delete post': 'Yayın sil',
    'Add New Issue': 'Yeni sayı ekle',
    'Edit Issue Detail': 'Sayı bilgileri düzenle',
    'Delete Issue': 'Sayı sil',
    'Publications': 'Yayınlar',
    'Imprint': 'Künye',
    'Publish': 'Yayınla',
    'Edit': 'Düzenle',
    'Delete': 'Sil',
    'Unpublish': 'Yayından kaldır',
    'Contents': 'İÇİNDEKİLER',
    'Add Chapter': 'Bölüm ekle',
    'Add Content': 'İçerik ekle',
    'Add Chapter Title': 'Bölüm başlığı ekle',
    'Chapter Title': 'Bölüm başlığı',
    'Content Type': 'İçerik tipi',
    'Content Title': 'İçerik başlığı',
    'Show Writer Name': 'Yazar adı görünsün',
    'Add Content to Chapter': 'Bölüme içerik ekle',
    'Add content before chapters': 'Bölümler öncesine içerik ekle',
    'Add content after chapters': 'Bölümler sonrasına içerik ekle',
    'Edit content detail': 'İçerik bilgileri düzenle',
    'Delete content': 'İçerik sil',
    'Edit Imprint': 'Künye düzenle',
    'Imprint not added.':'Künye eklenmedi.',
    'Title': 'Başlık',
    'Value': 'Değer',
    'Add Row': 'Satır ekle',
    'Save': 'Kaydet',
    'Upload image is in progress': 'Resim yükleme işlemi devam ediyor',
    'Add Text Box': 'Metin kutusu ekle',
    'Text under image (Optional)': 'Fotoğraf altı metin (İsteğe bağlı)',
    'Text': 'Metin',
    'Link display name (Optional)': 'Link görünen ad (İsteğe bağlı)',
    'Link (Optional)': 'Link (İsteğe bağlı)',
    'Record will be deleted': 'Kayıt silinecek',
    'Delete all content': 'Tüm kayıtları sil',
    'Contact Us': 'Bize yazın',
    'Settings': 'Ayarlar',
    'Team': 'Üyeler',
    'Create Account':'Hesap oluştur',
    'Log out':'Çıkış yap',
    'Account name':'Hesap adı'
  },
  login: {
    signIn: "Oturum aç",
    password: "Parola",
    rememberMe: "Beni hatırla",
    next: "İleri",
    email: "E-posta",
    forgotPassword: "Şifremi unuttum",
  },
  forgotPassword: {
    next: "İleri",
    email: "E-posta",
    forgotPassword: "Parolamı unuttum",
    sendForgotPasswordEmailMessage:
      "E-posta adresinize şifre sıfırlama bilgilerini içeren bir e-posta gönderildi (spam klasörünüzü de kontrol etmeniz önerilir).",
    sendForgotPasswordInfo:
      "E-posta adresinizi girip İleri butonuna bastıktan sonra şifrenizi nasıl yenileceğinizle ilgili bilgiler e-posta adresinize gönderilecektir.",
  },
  error: {
    resetPassword: "Daha önce şifre sıfırla yapılmış.",
    alreadyActivated: "Hesabınız daha önce aktifleştirilmiş.",
    email: "Hatalı e-posta adresi.",
    required: "Zorunlu alan.",
    passwordMustMatch: "Şifre ve şifre tekrarı bir biri ile eşleşmiyor.",
    unauthorized: "Oturum süresi dolmuştur. Tekrar giriş yapınız.",
    protectedPageMessage: "Bu sayfayı görebilmek için giriş yapmayı deneyiniz.",
    passwordMatches: "Parola, en az 8 karakter olmalı, sayı ve !@#$% gibi özel karakterler içermelidir.",
  },
  success: {
    accountActivated: "Hesabınız aktifleştirildi.",
  },
  home: {
    emptyMessage: "Henüz yayınınız bulunmamaktadır.",
    addNewPost: "Yeni yayın ekle",
    addNewIssue: "Yeni sayı ekle",
    addPost: "Yayın ekle",
    postName: "Yayın adı",
    issn: "ISSN",
    isbn: "ISBN",
    draft: "Taslak",
    published: "Yayınlananlar",
    iAmAuthorized: "Benimle paylaşılanlar",
  },
  createAccount: {
    createAccount: "Hesap oluştur",
    name: "Adı",
    surname: "Soyadı",
    email: "E-posta",
    password: "Parola",
    rePassword: "Parola tekrar",
    next: "İleri",
    login: "Oturum aç",
    hasAccount: "Hesabın varsa",
    passwordInfo: "Parola, en az 8 karakter olmalı, sayı ve sembol içermelidir.",
    sozlesme: "<2>Kullanıcı Sözleşmesi</2> ve <2>Gizlilik İlkeleri</2>’ni kabul ediyorum.",
    'Account name':'Hesap adı'
  },
  authroization: {
    members: "Kullanıcılar",
    memberGroups: "Kullanıcı grupları",
    managersAndOwners: "Yöneticiler & sahipler"
  },
  authorized: {
    emptyMessage: "Paylaşılan yayın bulunmamaktadır.",
  }
};

export default TR;
