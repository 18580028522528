import React, { useState, useRef, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { ButtonGroup, Grid, IconButton, Typography, Popper, ClickAwayListener, Grow, MenuItem, MenuList, Paper } from "@mui/material";
// Material Icons
import { DragHandle as DragHandleIcon, Edit, MoreVert as MoreVertIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import ContentDefinition from "../../contentDefinition";
import ContentDetailDefinition from "../../contentDetailDefinition";
// Components
import DeleteConfirmation from "components/deleteConfirmation";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface Props {
  contentId: any;
  dragProps?: DraggableProvidedDragHandleProps;
  reloadContents?: () => void;
  tenantId: any;
  authorizationContext: any;
}

const Content: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isMd } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [isHover, setHover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [deleteIssueConfirmation, setDeleteIssueConfirmation] = useState(false);
  const [isReadonlyMode, setIsReadonlyMode] = useState(false);
  const [content, setContent] = useState<any>();
  const [openContentDetailDefinitionAsDialog, setOpenContentDetailDefinitionAsDialog] = useState<any>();
  const [openContentDefinitionAsDialog, setOpenContentDefinitionAsDialog] = useState<any>();

  useEffect(() => {
    if (content) return;
    var id = props.contentId;
    proxyExecute(`Contents/${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setContent(data) },
      authorizedTenantId: props.tenantId
    });
  });

  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  const getContectMenu = () => {
    return <Popper
      style={{ zIndex: 2 }}
      transition
      open={openContextMenu}
      disablePortal role={undefined} anchorEl={anchorRef.current}
      placement={'bottom-end'}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                <MenuItem onClick={(e) => {
                  e.stopPropagation();
                  setHover(false)
                  setOpenContextMenu(false);
                  setOpenContentDefinitionAsDialog(true);
                }}>{t('Edit content detail')}</MenuItem>
                <MenuItem onClick={(e) => {
                  e.stopPropagation();
                  setHover(false)
                  setOpenContextMenu(false);
                  setDeleteIssueConfirmation(true);
                }}>
                  {t('Delete content')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>;
  }
  if (!content)
    return (<></>);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
      <Grid container alignItems="center" className={classes.content} justifyContent="space-between" onClick={() => {
        setIsReadonlyMode(true);
        setOpenContentDetailDefinitionAsDialog(true);
        setHover(false);
      }}>
        <Grid item className={classes.container}>
          <Grid container alignItems="center" justifyContent="space-between" className={classes.titleContentWrapper} style={{
            backgroundColor: isHover ? 'rgba(0, 0, 0, 0.04)' : ''
          }}>
            <Grid item className={classes.titleContentLeftContainer}>
              <Grid container >
                <Grid item xs={12}>
                  <Typography className={classes.contentTitle}>{content?.title}</Typography>
                </Grid>
                {content && content.writers && content.showWriter && (
                  <Grid item xs={12} style={{ marginTop: '2px' }}>
                    <Typography className={classes.writer}>
                      {content.writers.map((writer: any, index: number) => {
                        return writer.name + (index === content.writers.length - 1 ? '' : ', ')
                      })}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.titleContentRightContainer}>
              <Grid container alignItems="center">
                <Grid item>
                  <>
                    <Grid style={{ height: 44 }} item>
                      <ButtonGroup ref={anchorRef} >
                        {(!props.tenantId || props.authorizationContext.hasAccessToEditContentDetail || content.hasAccessToEditContentDetail) && !isMd && <IconButton {...props.dragProps} onClick={(e) => { e.stopPropagation(); setOpenContentDetailDefinitionAsDialog(true);setHover(false); }}>{(isHover || openContextMenu) && <Edit style={{ height: '44px' }} />}</IconButton>}
                        {(!props.tenantId || (props.authorizationContext.hasAccessToEditContent && props.authorizationContext.hasAccessToDeleteContent)) && !isMd
                          && <IconButton {...props.dragProps} onClick={(e) => { e.stopPropagation(); handleToggle(undefined); setHover(false);}}>{(isHover || openContextMenu) && !isMd && <MoreVertIcon style={{ height: '44px' }} />}</IconButton>}
                      </ButtonGroup>
                    </Grid>
                    {getContectMenu()}
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(!props.tenantId || props.authorizationContext.hasAccessToReorderContent) && !isMd && <Grid item>
          <IconButton {...props.dragProps}>{isHover && <DragHandleIcon />}</IconButton>
        </Grid>}
        {(props.tenantId && !props.authorizationContext.hasAccessToReorderContent) && <Grid style={{ width: 44 }}></Grid>}
      </Grid>

      {openContentDetailDefinitionAsDialog &&
        <ContentDetailDefinition
          isReadonly={isReadonlyMode}
          contentId={props.contentId}
          onClose={() => {
            setOpenContentDetailDefinitionAsDialog(false);
            setIsReadonlyMode(false);
          }} />}

      {openContentDefinitionAsDialog &&
        <ContentDefinition
          contentId={props.contentId}
          onClose={() => {
            setOpenContentDefinitionAsDialog(false);
            setContent(undefined);
          }} />}

      {deleteIssueConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteIssueConfirmation(false);
          var id = props.contentId;
          proxyExecute(`Contents/${id}`, HttpMethod.Delete, {
            setIsLoading: undefined,
            callback: () => { props.reloadContents && props.reloadContents() }
          })
        }}
          onCancelClicked={() => {
            setDeleteIssueConfirmation(false);
          }}></DeleteConfirmation>}
    </div>
  );
};

export default Content;
