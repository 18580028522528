import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Grid, Typography } from "@mui/material";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../components/hooks';
// Screens
import ContentManagement from "pages/post/posted/contentManagement";
// Components
import Publication from './publication'
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface Props {
  tenantId?: any;
  isPublished?: boolean;
  isApproved?: boolean;
  refreshData: boolean;
  loadingCompleted?: (itemCount: number) => void;
}

const PublicationContainer: React.FC<Props> = ({ refreshData, tenantId, isPublished, isApproved, loadingCompleted }) => {
  const { t, proxyExecute, isSm, isXs } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [publicationIdList, setPublicationIdList] = useState<any>();
  const [selectedPublicationId, setSelectedPublicationId] = React.useState<any>();
  const [openContentManagementAsDialog, setOpenContentManagementAsDialog] = React.useState<any>(false);

  useEffect(() => {
    if (refreshData)
      setPublicationIdList(undefined);
  }, [refreshData]);

  useEffect(() => {
    if (publicationIdList) return;

    proxyExecute(`Publications/List?IsApproved=${isApproved ?? false}&IsPublished=${isPublished ?? false}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setPublicationIdList(data);
        loadingCompleted && loadingCompleted(data.length);
      },
      authorizedTenantId: tenantId
    })
  }, [publicationIdList]);

  if (!publicationIdList || (publicationIdList && publicationIdList.length === 0))
    return <div></div>

  return (
    <Grid container>
      <Grid item xs={12} className={classes.issueContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" className={classes.editionTitleWrapper}>
              <Grid item>
                <Typography className={classes.editionName}>{t('Publications')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ alignItems: 'baseline' }}>
            {publicationIdList.map((publicationId: any) => {
              return (
                <Grid key={publicationId} item xs={ isXs ? 6 : (isSm ? 4 : 2)}>
                  <Publication publicationId={publicationId.id} tenantId={tenantId} onClick={(publication) => {
                    setSelectedPublicationId(publication.id);
                    setOpenContentManagementAsDialog(true);
                  }}
                    onPublicationDeleted={() => {
                      setPublicationIdList(undefined);
                    }}
                    onPublicationModified={() => {
                      setPublicationIdList(undefined);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {openContentManagementAsDialog === true &&
        <ContentManagement tenantId={tenantId} postType="publication" id={selectedPublicationId} onClose={() => {
          setOpenContentManagementAsDialog(false);
          setPublicationIdList(undefined);
        }}></ContentManagement>}
    </Grid>
  );
};

export default PublicationContainer;
